.title {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 33px;
    font-weight: 600;
    margin-bottom: 60px;

    span {
        font-size: 21px;
        font-weight: 800;
    }
}

.policy-content {
    padding-top: 50px;
    p {
        font-size: 19px;
        line-height: 33px;
        font-weight: 400;
        margin-bottom: 18px;

        span {
            font-weight: 700;
        }

        &.policy-title {
            color: $gray-100;
            margin-top: 20px;
            font-weight: 700;
            font-size: 22px;
        }
    }
}