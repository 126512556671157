@import url(https://fonts.googleapis.com/css?family=Quicksand:300,regular,500,600,700);
@import url(https://fonts.googleapis.com/css?family=Bebas+Neue:regular);
@import url(https://fonts.googleapis.com/css?family=Anton:regular);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,regular,500,600,700,800,300italic,italic,500italic,600italic,700italic,800italic);

* {
    box-sizing: border-box;
}
body {
    font-family: "Quicksand",sans-serif;
    color: #3c3c3c;
    font-size: 16px;
    line-height: 1.75;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100vw;
    overflow-x: hidden;
    background: #FFF;
}

html {
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

a {
    text-decoration: none;
    transition: all .3s ease;

    &:hover {
        color: $secondary;
    }
}

h1, h2, h3, h4, h5, h6, p, ul, li {
    padding: 0;
    margin: 0;
}

.policy-wrap {
    padding: 50px;
}