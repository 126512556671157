.banner-highlighted-text {
    font-size: 24px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 20px;
}

.esg-banner {
    padding-top: 135px;
    padding-bottom: 135px;

    @media (max-width: 600px) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .banner-title-light {
        padding-right: 150px;

        @media (max-width: 1366px) {
            padding-right: 0;
        }
    }

    .banner-content-light {
        padding: 0 80px;
    }
}

.esg-about-wrap {
    padding-top: 135px;
    padding-bottom: 135px;
    background: $gray-300;

    @media (max-width: 600px) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    img {
        width: 100%;
    }
}

.esg-about-content {
    padding-left: 100px;

    @media (max-width: 1200px) {
        padding-left: 60px;
    }

    @media (max-width: 820px) {
        padding: 0;
    }

    &.reverse {
        padding-left: 0;
        padding-right: 100px;

        @media (max-width: 1200px) {
            padding-right: 60px;
        }

        @media (max-width: 820px) {
            padding: 0;
        }
    }
}

.esg-img {
    border-radius: 5px;
    overflow: hidden;
}

.esg-content-wrap {
    margin-bottom: 100px;
}

.esg-content-wrap {
    &:nth-child(2) {
        .row {
            @media (max-width: 820px) {
                flex-direction: column-reverse;
            }
        }
    }
}