.footer {
    &-top {
        padding-top: 90px;
        padding-bottom: 90px;
    }

    &-content {
        font-size: 16px;
        line-height: 28px;
        font-size: 'OpenSans', sans-serif;
        float: 300;
        padding: 20px;

        &.w-50 {
            @media (max-width: 1024px) {
                width: 65% !important;
            }

            @media (max-width: 600px) {
                width: 100% !important;
            }
        }
    }

    &-col-title {
        font-size: 18px;
        line-height: 23px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 18px;
    }

    &-item {
        font-family: 'OpenSans', sans-serif;
        font-size: 14px;
        line-height: 30px;
        position: relative;
        transition: all .3s ease;
        color: $white;
        padding-left: 15px;

        &::before {
            content: '>';
            position: absolute;
            transform: translateX(-15px);
            transition: all .3s ease;
        }

        &:hover {
            color: $secondary;

            &::before {
                transform: translateX(-12px);
            }
        }
    }

    &-menu {
        ul, li {
            padding: 0;
            margin: 0;
            list-style: none;
        }
    }

    &-bottom {
        padding-top: 15px;
        padding-bottom: 15px;

        a {
            color: $white;
            transition: all .3s ease;
            
            &:hover {
                color: $secondary;
            }
        }
    }
}