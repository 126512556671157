.logo-comming-soon {
    width: 30vw;
    height: 8vh;
    padding-right: 3rem ;
}

/* For devices with width less than 600px */
@media only screen and (max-width: 600px) {
    .logo-comming-soon {
        width: 45vw;
        height: 5vh;
    }
}