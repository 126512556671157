.navbar {
    padding: 0;
    border-bottom: 1px solid rgba(0,0,0,.04);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9;
    background: $white;

    &-brand {
        margin-top: 20px;
        margin-bottom: 20px;

        img {
            width: auto;
            max-width: 100%;
            max-height: 50px;
        }
    }

    &-collapse {
        color: #3c3c3c;
    }
}

.nav-link {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    padding: 14px 0;
    margin: 0 16px;
    text-transform: uppercase;
}

.header-shadow {
    box-shadow: 0 1px 5px rgb(0 0 0 / 6%)
}