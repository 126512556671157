.about-banner {
    padding-top: 135px;
    padding-bottom: 135px;
    background: $gray-300;

    @media (max-width: 600px) {
        padding-top: 100px;
        padding-bottom: 100px;
    }
}

.banner {
    &-title-light {
        font-size: 46px;
        line-height: 61px;
        font-weight: 600;
        color: $gray-200;
        margin-bottom: 20px;

        @media (max-width: 820px) {
            font-size: 40px;
            line-height: 55px;
        }

        @media (max-width: 600px) {
            font-size: 35px;
            line-height: 50px;
        }
    }

    &-content-light {
        font-size: 23px;
        line-height: 40px;
        color: $gray-100;
        font-weight: 800;
        padding: 0 45px;

        @media (max-width: 820px) {
            padding: 0;
        }

        @media (max-width: 600px) {
            font-size: 18px;
            line-height: 33px;
        }
    }
}

.leader {
    &-box {
        border-radius: 5px;
        overflow: hidden;

        &:hover {
            .leader {
                &-info {
                    background: $secondary;
                }

                &-name {
                    color: #222;
                }
            }
        }
    }

    &-img {
        min-height: 262px;
        width: 100%;

        @media (max-width: 820px) {
            min-height: 170px;
        }
    }

    &-info {
        padding: 25px 0;
        background: #222;
        transition: all .5s ease;
    }

    &-name {
        font-size: 18px;
        line-height: 23px;
        color: $white;
        font-weight: 700;
    }

    &-position {
        font-size: 16px;
        line-height: 28px;
        font-weight: 300;
        color: $white;
    }
}

.leaders-wrap {
    padding-top: 135px;
    padding-bottom: 135px;

    @media (max-width: 600px) {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .banner-title-light {
        margin-bottom: 60px;
        padding: 0 340px;

        @media (max-width: 1366px) {
            padding: 0 250px;
        }

        @media (max-width: 1024px) {
            padding: 0 160px;
        }

        @media (max-width: 820px) {
            padding: 0 50px;
        }

        @media (max-width: 600px) {
            padding: 0;
        }
    }
}