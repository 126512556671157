.banner {
    min-height: 550px;
    height: 100vh;
    position: relative;

    &-video {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
    }

    &-title {
        font-family: "Bebas Neue", cursive;
        font-size: 80px;
        line-height: 104px;
        font-weight: 700;
        color: $white;
        margin-bottom: 18px;

        @media (max-width: 820px) {
            font-size: 65px;
            line-height: 85px;
            margin-bottom: 0;
        }
    }

    &-content {
        font-size: 24px;
        line-height: 31px;
        font-weight: 500;
        color: $white;

        &-wrap {
            position: relative;
            z-index: 3;
            top: 40%;
        }
    }
}

.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: rgba(0,0,0,.5);
}

.description {
    &-wrap {
        padding: 130px 0;
    }

    &-title {
        margin: 12px 0 18px 0;
        color: $secondary;
        font-size: 46px;
        line-height: 51px;
        font-weight: 700;
    }
}

.desc {
    font-size: 23px;
    line-height: 40px;
    color: $gray-100;
    font-weight: 800;
    padding: 0 255px;

    @media (max-width: 1024px) {
        padding: 0 145px;
    }

    @media (max-width: 820px) {
        padding: 0 50px;
    }
}

.logo-wrap {
    img {
        width: 82px;
    }
}

.letter {
    font-family: "Anton",sans-serif;
    font-size: 100px;
    line-height: 130px;
    font-weight: 700;
    color: #011050;
    text-transform: uppercase;
    margin-bottom: 18px;

    @media (max-width: 820px) {
        font-size: 85px;
        line-height: 100px;
        margin-bottom: 0;
    }

    &-box {
        padding: 30px;
        transition: all .3s ease;

        &:hover {
            background: #596dc6;

            .letter-text {
                color: $white;
            }
        }

        &-wrap {
            margin-top: 120px;
        }
    }

    &-text {
        font-size: 16px;
        line-height: 21px;
        text-transform: uppercase;
        color: $gray-200;
        transition: all .3s ease;
        font-weight: 700;
        margin-bottom: 18px;

        @media (max-width: 820px) {
            font-size: 13px;
            line-height: 28px;
            margin-bottom: 0;
        }
    }
}

.mining-map-wrap {
    padding-top: 50px;
    padding-bottom: 80px;
    background: $gray-300;
}

.map-title {
    font-size: 26px;
    line-height: 29px;
    font-weight: 500;
    color: $gray-200;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 50px;
}

.map-img {
    width: 450px;

    @media (max-width: 600px) {
        width: 70%;
    }
}

.quote {
    margin-bottom: 30px;
    font-size: 36px;
    line-height: 50px;
    font-weight: 600;
    color: $white;
    font-style: italic;

    @media (max-width: 600px) {
        font-size: 28px;
        line-height: 45px;
    }

    &-wrap {
        padding-top: 135px;
        padding-bottom: 135px;
        background: $secondary;
    }
}

.writer {
    font-size: 18px;
    line-height: 23px;
    font-weight: 700;
    font-style: italic;
    color: $white;
}

.feature {
    &-wrap {
        padding: 90px 0;
    }

    &-img {
        width: 700px;
        margin-bottom: 10px;

        @media (max-width: 1024px) {
            width: 550px;
        }

        @media (max-width: 820px) {
            width: 515px;
        }

        @media (max-width: 600px) {
            width: 100%;
        }
    }

    &-text {
        font-size: 23px;
        line-height: 40px;
        color: $gray-100;
        font-weight: 800;
        margin-bottom: 18px;
        padding-right: 50px;

        @media (max-width: 1024px) {
            font-size: 19px;
            line-height: 32px;
        }

        @media (max-width: 820px) {
            padding-right: 0;
        }
    }

    &-icon {
        max-width: 60px;
        margin-bottom: 25px;

        @media (max-width: 1024px) {
            margin-bottom: 20px;
        }

        &-box {
            padding: 30px;
        }
    }

    &-title {
        font-size: 16px;
        line-height: 21px;
        color: $gray-200;
        font-weight: 700;

        @media (max-width: 1024px) {
            font-size: 14px;
            line-height: 19px;
        }
    }
}

.feature-box-wrap {
    padding-bottom: 30px;
    padding-top: 30px;
}

.letter-wrap {
    padding: 20px;
}

.single-letter {
    font-size: 100px;
    line-height: 130px;
    color: $blue;
    font-weight: 700;
    font-family: 'Anton', sans-serif;

    @media (max-width: 1024px) {
        font-size: 85px;
        line-height: 120px;
    }
}

.single-meaning {
    font-size: 26px;
    line-height: 34px;
    font-weight: 600;
    color: $secondary;

    @media (max-width: 1024px) {
        font-size: 22px;
        line-height: 30px;
    }

    @media (max-width: 820px) {
        font-size: 16px;
        line-height: 25px;
    }
}

.about {
    &-wrap {
        height: 100vh;
        overflow: hidden;
    }

    &-img {
        min-width: 100%;
        min-height: 100%;
        object-fit: cover;
        position: absolute;
    }

    &-content-wrap {
        padding: 150px 80px;
        position: relative;
        z-index: 3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        @media (max-width: 820px) {
            padding: 120px 45px;
        }
    }

    &-title {
        font-size: 50px;
        line-height: 65px;
        font-weight: 700;
        margin-bottom: 20px;
    }

    &-text {
        font-size: 26px;
        line-height: 43px;
        font-weight: 800;

        @media (max-width: 1024px) {
            font-size: 22px;
            line-height: 38px;
        }
    }
}

.contact {
    &-wrap {
        padding-top: 135px;
        padding-bottom: 135px;
    }

    &-section-title {
        font-size: 24px;
        line-height: 31px;
        font-weight: 600;
        color: $gray-200;
        margin-bottom: 30px;
    }

    &-title {
        font-size: 16px;
        line-height: 21px;
        font-weight: 700;
        color: $gray-200;
        margin-bottom: 10px;
    }

    &-desc {
        font-size: 14px;
        line-height: 23px;
        font-weight: 400;
        color: $gray-400;
        width: 55%;

        @media (max-width: 600px) {
            width: 100%;
        }
    }

    &-info {
        margin-bottom: 55px;
    }
}

.google-map-wrap {
    margin-bottom: 55px;
}

.form-title {
    font-size: 16px;
    line-height: 28px;
    color: $gray-400;
    font-weight: 400;
    margin-bottom: 33px;
}

.form-control {
    padding-left: 20px;
    min-height: 50px;
    background-color: #f8f8f8;
    border-color: #efefef;
     
    &:focus {
        box-shadow: none;
        background-color: #f8f8f8;
        border-color: #efefef;
    }
}

.submit-btn {
    background: $blue;
    border: 2px solid $blue;
    padding: 15px 24px;
    min-width: 160px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: $white;
    text-transform: uppercase;
    transition: all .3s ease;

    &:hover {
        color: $gray-400;
        background: transparent;
    }
}